<template>
  <v-dialog max-width="600" v-model="status.isModalOpen">
    <v-form ref="variantForm" v-if="!!product">
      <v-card>
        <v-card-title class="grey lighten-3">
          <span class="title">{{ product.name | textEllipsis(25) }} - DETAILS</span>
          <v-spacer />
          <v-icon @click="closeModal">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-carousel height="200" hide-delimiters>
                <v-carousel-item
                  :src="image"
                  v-for="(image, index) in product.images"
                  :key="`${image}-${index}`"
                />
              </v-carousel>
            </v-col>
            <v-col cols="6">
              <div class="fs-16 fw-700">{{ product.name }}</div>
              <div class="fs-12">#{{ product.wcProductId }}</div>
              <div class="mt-2 fw-700">{{ product.variants.length }} variants</div>
              <div class="mt-2">Categories:</div>
              <div class="d-flex flex-wrap">
                <v-chip
                  class="mt-2 mr-2"
                  v-for="category in product.categories"
                  :key="category.name"
                  >{{ category.name }}</v-chip
                >
              </div>
              <div class="mt-2">Tags:</div>
              <div class="d-flex flex-wrap">
                <v-chip class="mt-2 mr-2" v-for="tag in product.tags" :key="tag">{{ tag }}</v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'DetailModal',
  data: () => ({
    product: null,
    status: {
      isModalOpen: false,
    },
  }),
  methods: {
    openModal(product) {
      this.product = product;
      this.$set(this.status, 'isModalOpen', true);
    },
    closeModal() {
      this.$set(this.status, 'isModalOpen', false);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
