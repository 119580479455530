<template>
  <div>
    <page-header title="Product Analysis" />
    <select-view
      :products="products"
      @compare="onCompare"
      v-if="selectedProducts.length === 0"
    />
    <single-analytics
      :selected-product="selectedProducts[0]"
      @back="onBack"
      v-if="selectedProducts.length === 1"
    />
    <multi-analytics
      :selected-products="selectedProducts"
      @back="onBack"
      v-if="selectedProducts.length > 1"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/App/PageHeader.vue';
import SelectView from '@/components/Main/ProductsPage/SelectView.vue';
import SingleAnalytics from '@/components/Main/ProductsPage/SingleAnalytics.vue';
import MultiAnalytics from '@/components/Main/ProductsPage/MultiAnalytics.vue';
import ProductService from '@/services/ProductService';

export default {
  name: 'ProductsPage',
  mixins: [ProductService],
  components: {
    PageHeader,
    SelectView,
    SingleAnalytics,
    MultiAnalytics,
  },
  data: () => ({
    products: [],
    selectedProducts: [],
    status: {
      isListView: true,
    },
  }),
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      this.products = await this.getProducts();
    },
    onSwitchView(isListView) {
      this.$set(this.status, 'isListView', isListView);
    },
    onCompare(selectedProducts) {
      this.selectedProducts = selectedProducts;
    },
    onBack() {
      this.selectedProducts = [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
