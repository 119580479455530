<template>
  <v-card class="product-card">
    <!-- PRODUCT CONTAINER -->
    <div class="overview-container">
      <img
        :src="product.images.length > 0 ? product.images[0] : defaultProductImageUrl"
        width="150"
      />
      <div class="text-center">
        <div style="color: #727272" class="mt-6 fs-24">
          {{ product.name | textEllipsis(20) }}
        </div>
        <div style="color: #9fa8da" class="mt-2 fs-14">#{{ product.wcProductId }}</div>
        <a
          style="color: #095473"
          class="d-block mt-12 fs-12 fw-600"
          @click="$emit('viewDetail', product)"
          >View Product Description</a
        >
      </div>
    </div>
    <div class="flex-1 pa-6 pl-12">
      <v-row>
        <v-col cols="3">
          <div style="color: #095473" class="fs-12 fw-800">Total Sales</div>
          <div style="color: #5394b0" class="d-flex">
            <div class="mt-2 fs-16 fw-700">$</div>
            <div class="fs-32 fw-600">
              {{ totalSales | numberFormatter }}
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div style="color: #095473" class="fs-12 fw-800">Average Price</div>
          <div style="color: #5394b0" class="d-flex">
            <div class="mt-2 fs-16 fw-700">$</div>
            <div class="fs-32 fw-600">
              {{ (totalSales / totalOrders) | numberFormatter }}
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div style="color: #095473" class="fs-12 fw-800">Orders</div>
          <div style="color: #5394b0" class="fs-32 fw-600">
            {{ totalOrders | numberFormatter }}
          </div>
        </v-col>
        <v-col cols="3">
          <div style="color: #095473" class="fs-12 fw-700">Frequent Buyers</div>
          <div class="d-flex align-center buyer" v-for="buyer in frequentBuyers" :key="buyer._id">
            <div style="color: #5798b3" class="fw-700">
              {{ buyer.firstName }} {{ buyer.lastName }}
            </div>
            <div class="ml-4 fs-12 fw-700 text-uppercase">
              {{ buyer.address.state }},
              {{ buyer.address.country }}
            </div>
          </div>
        </v-col>
      </v-row>
      <div>
        <div class="d-flex justify-space-between align-center">
          <div style="color: #3e89a8" class="fw-800">BUYERS BY CHANNEL</div>
          <div class="d-flex justify-space-between mt-2">
            <div
              class="d-flex align-center"
              v-for="(value, channel) in buyerChannelAnalytics"
              :key="channel"
            >
              <v-progress-circular
                :rotate="-90"
                :size="60"
                :width="8"
                :value="value"
                color="#3E88A7"
              >
                <img :src="`/img/${channel}-colored.svg`" />
              </v-progress-circular>
              <div style="color: #3e89a8" class="mx-2 fs-14 fw-700">
                {{ value | numberFormatter }}%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-8">
        <!-- STATISTICS CONTAINER -->
        <div class="statistics-container">
          <div style="color: #095473" class="fs-12 fw-800">Total Visitors</div>
          <div style="color: #5394b0" class="fs-32 fw-600">
            {{ totalVisitors | numberFormatter }}
          </div>
          <div style="color: #095473" class="mt-4 fs-12 fw-800">Total Buyers</div>
          <div style="color: #5394b0" class="fs-32 fw-600">
            {{ totalBuyers | numberFormatter }}
          </div>
        </div>
        <!-- ANALYTICS CONTAINER -->
        <div class="analytics-container">
          <div style="color: #3e89a8" class="d-flex justify-end fw-800">
            SEE HOW FAR VISITORS ARE GETTING
          </div>
          <div class="d-flex align-center mt-4">
            <div class="channel-label">
              <div style="color: #4f4f4f" class="fs-12">Added to cart</div>
              <img class="ml-2" src="/img/cart-blue.svg" />
            </div>
            <v-progress-linear
              color="#053D60"
              :height="6"
              :value="visitorProgressAnalytics.cartVisitors"
            />
          </div>
          <div style="color: #1ab9c0" class="d-flex justify-end fs-12 fw-700">
            {{
              (visitorProgressAnalytics.cartVisitors - visitorProgressAnalytics.checkoutVisitors)
                | numberFormatter
            }}% VISITORS DROPOFF
          </div>
          <div class="d-flex align-center">
            <div class="channel-label">
              <div style="color: #4f4f4f" class="fs-12">Reached checkout</div>
              <img class="ml-2" src="/img/tote-blue.svg" />
            </div>
            <v-progress-linear
              color="#0C748D"
              :height="6"
              :value="visitorProgressAnalytics.checkoutVisitors"
            />
          </div>
          <div style="color: #1ab9c0" class="d-flex justify-end fs-12 fw-700">
            {{
              (visitorProgressAnalytics.checkoutVisitors - visitorProgressAnalytics.paymentVisitors)
                | numberFormatter
            }}% VISITORS DROPOFF
          </div>
          <div class="d-flex align-center">
            <div class="channel-label">
              <div style="color: #4f4f4f" class="fs-12">Payment</div>
              <img class="ml-2" src="/img/pos-blue.svg" />
            </div>
            <v-progress-linear
              color="#3E89A8"
              :height="6"
              :value="visitorProgressAnalytics.paymentVisitors"
            />
          </div>
          <div style="color: #1ab9c0" class="d-flex justify-end fs-12 fw-700">
            0% VISITORS DROPOFF
          </div>
          <div class="d-flex align-center">
            <div class="channel-label">
              <div style="color: #4f4f4f" class="fs-12">Conversion</div>
              <img class="ml-2" src="/img/gift-blue.svg" />
            </div>
            <v-progress-linear
              color="#6BBBD4"
              :height="6"
              :value="visitorProgressAnalytics.conversionVisitors"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import ProductService from '@/services/ProductService';

export default {
  name: 'SingleProductAnalytics',
  mixins: [ProductService],
  props: ['product'],
  data: () => ({
    totalSales: 0,
    totalOrders: 0,
    totalVisitors: 0,
    totalBuyers: 0,
    frequentBuyers: [],
    buyerChannelAnalytics: {
      facebook: 0,
      instagram: 0,
      google: 0,
      tiktok: 0,
    },
    visitorProgressAnalytics: {
      cartVisitors: 0,
      checkoutVisitors: 0,
      paymentVisitors: 0,
      conversionVisitors: 0,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.fetchProductOverview();
      this.fetchProductTrafficHistory();
      this.fetchProductBuyerChannelAnalytics();
      this.fetchProductVisitorProgressAnalytics();
    },
    async fetchProductOverview() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getProductOverview(this.product._id, params);
      this.totalSales = data.totalSales;
      this.totalOrders = data.totalOrders;
      this.frequentBuyers = data.frequentBuyers;
    },
    async fetchProductTrafficHistory() {
      const params = { dateRange: this.fullDateRange };
      const { visitorsData, buyersData } = await this.getProductTrafficHistory(
        this.product._id,
        params,
      );
      this.totalVisitors = visitorsData.reduce((total, item) => total + item.visitors, 0);
      this.totalBuyers = buyersData.reduce((total, item) => total + item.buyers, 0);
    },
    async fetchProductBuyerChannelAnalytics() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getProductBuyerChannelAnalytics(this.product._id, params);
      const totalBuyers = Object.values(data).reduce((total, count) => total + count, 0) || 1;
      this.buyerChannelAnalytics = {
        facebook: ((data.Facebook || 0) * 100) / totalBuyers,
        instagram: ((data.Instagram || 0) * 100) / totalBuyers,
        google: ((data.Google || 0) * 100) / totalBuyers,
        tiktok: ((data.Tiktok || 0) * 100) / totalBuyers,
      };
    },
    async fetchProductVisitorProgressAnalytics() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getProductVisitorProgressAnalytics(this.product._id, params);
      const totalVisitors = data.totalVisitors || 1;
      this.visitorProgressAnalytics = {
        cartVisitors: (data.cartVisitors * 100) / totalVisitors,
        checkoutVisitors: (data.checkoutVisitors * 100) / totalVisitors,
        paymentVisitors: (data.paymentVisitors * 100) / totalVisitors,
        conversionVisitors: (data.paymentVisitors * 100) / totalVisitors,
      };
    },
  },
  watch: {
    dateRange(newValue, oldValue) {
      if (oldValue.length === 1 && newValue.length === 2) {
        this.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  flex: 1;
  display: flex;
  margin: 16px 0;

  .overview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    max-width: 250px;
    border-radius: 0 !important;
    border-right: 2px solid #dee2e5;
  }
  .statistics-container {
    display: flex;
    flex-direction: column;

    .buyer {
      white-space: nowrap;
    }
  }
  .analytics-container {
    flex: 1;
    margin-left: 32px;

    .channel-label {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      min-width: 135px;
    }
  }
}
</style>
