export default {
  methods: {
    getProducts() {
      return $http.get('/products');
    },
    getProductOverview(productId, params) {
      return $http.get(`/products/${productId}/product-overview`, { params });
    },
    getProductTrafficHistory(productId, params) {
      return $http.get(`/products/${productId}/traffic-history`, { params });
    },
    getProductBuyerChannelAnalytics(productId, params) {
      return $http.get(`/products/${productId}/buyer-channel-analytics`, { params });
    },
    getProductVisitorProgressAnalytics(productId, params) {
      return $http.get(`/products/${productId}/visitor-progress-analytics`, { params });
    },
  },
};
