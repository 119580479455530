<template>
  <div class="d-flex">
    <!-- LEFT CARD -->
    <v-card class="left-card" color="#3E89A8" dark>
      <a class="d-flex fw-700" href="#" @click.prevent="$emit('back')">
        <v-icon size="20">mdi-chevron-left-circle-outline</v-icon>
        <span class="ml-2 fs-14 white--text">Back to Product Selection</span>
      </a>
      <div class="flex-1 d-flex justify-center align-center">
        <img style="opacity: 0.1" src="/img/logo-full.svg" height="100" />
      </div>
      <div class="mb-8">
        Click below to modify<br />
        the analyzed products.
      </div>
      <v-btn large rounded color="#465286" @click="refresh">Product Analysis</v-btn>
    </v-card>
    <!-- RIGHT CARD -->
    <v-card class="right-card">
      <!-- PRODUCT CONTAINER -->
      <div class="product-container">
        <img
          :src="
            (selectedProduct.images.length > 0 ? selectedProduct.images[0] : null) ||
            defaultProductImageUrl
          "
          width="150"
        />
        <div class="text-center">
          <div style="color: #727272" class="mt-6 fs-24">
            {{ selectedProduct.name }}
          </div>
          <div style="color: #9fa8da" class="mt-2 fs-14">
            #{{ selectedProduct.wcProductId }}
          </div>
          <a
            style="color: #095473"
            class="d-block mt-12 fs-12 fw-600"
            @click="$refs.detailModal.openModal(selectedProduct)"
            >View Product Description</a
          >
        </div>
      </div>
      <!-- STATISTICS CONTAINER -->
      <div class="statistics-container">
        <div style="color: #095473" class="fs-12 fw-800">Total Sales</div>
        <div style="color: #5394b0" class="d-flex">
          <div class="mt-2 fs-16 fw-700">$</div>
          <div class="fs-32 fw-600">{{ totalSales | numberFormatter }}</div>
        </div>
        <div style="color: #095473" class="mt-8 fs-12 fw-800">Average Price</div>
        <div style="color: #5394b0" class="d-flex">
          <div class="mt-2 fs-16 fw-700">$</div>
          <div class="fs-32 fw-600">
            {{ (totalSales / totalOrders) | numberFormatter }}
          </div>
        </div>
        <div style="color: #0a5473" class="mt-8 fs-12 fw-800">Orders</div>
        <div style="color: #5394b0" class="d-flex">
          <div class="fs-32 fw-600">{{ totalOrders | numberFormatter }}</div>
        </div>
        <div style="color: #095473" class="mt-8 mb-2 fs-12 fw-700">Frequent Buyers</div>
        <div class="d-flex align-center buyer" v-for="buyer in frequentBuyers" :key="buyer._id">
          <div style="color: #5798b3" class="fw-700">
            {{ buyer.firstName }} {{ buyer.lastName }}
          </div>
          <div class="ml-4 fs-12 fw-700 text-uppercase">
            {{ buyer.address.state }},
            {{ buyer.address.country }}
          </div>
        </div>
      </div>
      <!-- ANALYTICS CONTAINER -->
      <div class="analytics-container">
        <div class="d-flex align-center">
          <img src="/img/sales-card-blue.svg" />
          <div style="color: #3e89a8" class="ml-2 fw-800">VISITORS AND BUYERS</div>
          <v-spacer />
          <help-tooltip v-if="!totalVisitors">
            Data will not be available until tracking is configured.
          </help-tooltip>
        </div>
        <div class="d-flex align-center mt-2">
          <div style="color: #095473" class="fs-12 fw-700">Total Visitors</div>
          <div style="color: #5394b0" class="ml-2 fs-32 fw-600">
            {{ totalVisitors | numberFormatter }}
          </div>
          <v-spacer />
          <div style="color: #095473" class="fs-12 fw-700">Total Buyers</div>
          <div style="color: #5394b0" class="ml-2 fs-32 fw-600">
            {{ totalBuyers | numberFormatter }}
          </div>
        </div>
        <div class="mt-4" id="trafficChartContainer"></div>
        <div class="d-flex align-center mt-4">
          <img src="/img/sales-card-blue.svg" />
          <div style="color: #3e89a8" class="ml-2 fw-800">BUYERS BY CHANNEL</div>
          <v-spacer />
          <help-tooltip v-if="!totalVisitors">
            Data will not be available until tracking is configured.
          </help-tooltip>
        </div>
        <div class="d-flex justify-space-between mt-4">
          <div
            class="d-flex align-center"
            v-for="(value, channel) in buyerChannelAnalytics"
            :key="channel"
          >
            <v-progress-circular :rotate="-90" :size="60" :width="8" :value="value" color="#3E88A7">
              <img :src="`/img/${channel}-colored.svg`" />
            </v-progress-circular>
            <div style="color: #3e89a8" class="mx-2 fs-14 fw-700">
              {{ value | numberFormatter }}%
            </div>
          </div>
        </div>
        <div style="color: #3e89a8" class="d-flex justify-end mt-8 fw-800">
          SEE HOW FAR VISITORS ARE GETTING
        </div>
        <div class="d-flex align-center mt-4">
          <div class="channel-label">
            <div style="color: #4f4f4f" class="fs-12">Added to cart</div>
            <img class="ml-2" src="/img/cart-blue.svg" />
          </div>
          <v-progress-linear
            color="#053D60"
            :height="6"
            :value="visitorProgressAnalytics.cartVisitors"
          />
        </div>
        <div style="color: #1ab9c0" class="d-flex justify-end fs-12 fw-700">
          {{
            (visitorProgressAnalytics.cartVisitors - visitorProgressAnalytics.checkoutVisitors)
              | numberFormatter
          }}% VISITORS DROPOFF
        </div>
        <div class="d-flex align-center">
          <div class="channel-label">
            <div style="color: #4f4f4f" class="fs-12">Reached checkout</div>
            <img class="ml-2" src="/img/tote-blue.svg" />
          </div>
          <v-progress-linear
            color="#0C748D"
            :height="6"
            :value="visitorProgressAnalytics.checkoutVisitors"
          />
        </div>
        <div style="color: #1ab9c0" class="d-flex justify-end fs-12 fw-700">
          {{
            (visitorProgressAnalytics.checkoutVisitors - visitorProgressAnalytics.paymentVisitors)
              | numberFormatter
          }}% VISITORS DROPOFF
        </div>
        <div class="d-flex align-center">
          <div class="channel-label">
            <div style="color: #4f4f4f" class="fs-12">Payment</div>
            <img class="ml-2" src="/img/pos-blue.svg" />
          </div>
          <v-progress-linear
            color="#3E89A8"
            :height="6"
            :value="visitorProgressAnalytics.paymentVisitors"
          />
        </div>
        <div style="color: #1ab9c0" class="d-flex justify-end fs-12 fw-700">
          0% VISITORS DROPOFF
        </div>
        <div class="d-flex align-center">
          <div class="channel-label">
            <div style="color: #4f4f4f" class="fs-12">Conversion</div>
            <img class="ml-2" src="/img/gift-blue.svg" />
          </div>
          <v-progress-linear
            color="#6BBBD4"
            :height="6"
            :value="visitorProgressAnalytics.conversionVisitors"
          />
        </div>
      </div>
    </v-card>

    <detail-modal ref="detailModal" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import ProductService from '@/services/ProductService';
import HelpTooltip from '@/components/App/HelpTooltip.vue';
import DetailModal from './DetailModal.vue';

HighchartsMore(Highcharts);

export default {
  name: 'SingleAnalytics',
  mixins: [ProductService],
  components: {
    DetailModal,
    HelpTooltip,
  },
  props: ['selectedProduct'],
  data: () => ({
    totalSales: 0,
    totalOrders: 0,
    totalVisitors: 0,
    totalBuyers: 0,
    frequentBuyers: [],
    visitorsHistory: {},
    buyersHistory: {},
    buyerChannelAnalytics: {
      facebook: 0,
      instagram: 0,
      google: 0,
      tiktok: 0,
    },
    visitorProgressAnalytics: {
      cartVisitors: 0,
      checkoutVisitors: 0,
      paymentVisitors: 0,
      conversionVisitors: 0,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.fetchProductOverview();
      this.fetchProductTrafficHistory();
      this.fetchProductBuyerChannelAnalytics();
      this.fetchProductVisitorProgressAnalytics();
    },
    async fetchProductOverview() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getProductOverview(this.selectedProduct._id, params);
      this.totalSales = data.totalSales;
      this.totalOrders = data.totalOrders;
      this.frequentBuyers = data.frequentBuyers;
    },
    async fetchProductTrafficHistory() {
      const params = { dateRange: this.fullDateRange };
      const { visitorsData, buyersData } = await this.getProductTrafficHistory(
        this.selectedProduct._id,
        params,
      );
      this.totalVisitors = visitorsData.reduce((total, item) => total + item.visitors, 0);
      this.totalBuyers = buyersData.reduce((total, item) => total + item.buyers, 0);
      this.visitorsHistory = visitorsData.reduce((history, item) => {
        history[item.date] = item.visitors;
        return history;
      }, {});
      this.buyersHistory = buyersData.reduce((history, item) => {
        history[item.date] = item.buyers;
        return history;
      }, {});
      this.updateTrafficChart();
    },
    async fetchProductBuyerChannelAnalytics() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getProductBuyerChannelAnalytics(this.selectedProduct._id, params);
      const totalBuyers = Object.values(data).reduce((total, count) => total + count, 0) || 1;
      this.buyerChannelAnalytics = {
        facebook: ((data.Facebook || 0) * 100) / totalBuyers,
        instagram: ((data.Instagram || 0) * 100) / totalBuyers,
        google: ((data.Google || 0) * 100) / totalBuyers,
        tiktok: ((data.Tiktok || 0) * 100) / totalBuyers,
      };
    },
    async fetchProductVisitorProgressAnalytics() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getProductVisitorProgressAnalytics(this.selectedProduct._id, params);
      const totalVisitors = data.totalVisitors || 1;
      this.visitorProgressAnalytics = {
        cartVisitors: (data.cartVisitors * 100) / totalVisitors,
        checkoutVisitors: (data.checkoutVisitors * 100) / totalVisitors,
        paymentVisitors: (data.paymentVisitors * 100) / totalVisitors,
        conversionVisitors: (data.paymentVisitors * 100) / totalVisitors,
      };
    },
    updateTrafficChart() {
      const self = this;
      const visitors = this.chartDateRange.map(date => this.visitorsHistory[date] || 0);
      const buyers = this.chartDateRange.map(date => this.buyersHistory[date] || 0);
      Highcharts.chart('trafficChartContainer', {
        chart: {
          type: 'column',
          height: 120,
          spacing: 0,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        legend: {
          align: 'right',
        },
        xAxis: {
          visible: false,
          minPadding: 0,
          maxPadding: 0,
          categories: this.chartDateRange,
        },
        yAxis: {
          visible: false,
          min: 0,
          maxPadding: 0,
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: '#FFA521',
          shadow: false,
          style: {
            fontSize: '16px',
            color: '#FFFFFF',
          },
          formatter() {
            return `
              ${this.x}<br />
              Visitors: ${self.visitorsHistory[this.x] || 0}<br />
              Buyers: ${self.buyersHistory[this.x] || 0}
            `;
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            groupPadding: 0,
          },
        },
        series: [
          {
            name: 'Visitors',
            data: visitors,
            stack: 'group',
            color: '#FFA521',
            borderRadius: 3,
            borderWidth: 0,
            pointWidth: 6,
          },
          {
            name: 'Buyers',
            data: buyers,
            stack: 'group',
            color: '#3E89A8',
            borderRadius: 3,
            borderWidth: 0,
            pointWidth: 6,
          },
        ],
      });
    },
  },
  watch: {
    dateRange(newValue, oldValue) {
      if (oldValue.length === 1 && newValue.length === 2) {
        this.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-card {
  display: flex;
  flex-direction: column;
  padding: 50px 72px 50px 40px;
  min-width: 360px;
  text-align: center;
}
.right-card {
  flex: 1;
  display: flex;
  margin-left: -32px;
  z-index: 1;

  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    max-width: 320px;
    border-radius: 0 !important;
    border-right: 2px solid #dee2e5;
  }
  .statistics-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px;

    .buyer {
      white-space: nowrap;
    }
  }
  .analytics-container {
    flex: 1;
    padding: 50px 50px 50px 0;

    .channel-label {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      min-width: 135px;
    }
  }
}
</style>
