<template>
  <v-dialog max-width="700" v-model="status.isModalOpen">
    <v-form ref="variantForm" v-if="!!product">
      <v-card>
        <v-card-title class="grey lighten-3">
          <span class="title">{{ product.name | textEllipsis(25) }} - VARIANTS</span>
          <v-spacer />
          <v-icon @click="closeModal">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table :headers="variantsTableHeaders" :items="product.variants" item-key="_id">
            <template v-slot:item.description="{ item }">
              <div v-html="item.description" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'VariantModal',
  data: () => ({
    product: null,
    status: {
      isModalOpen: false,
    },
  }),
  computed: {
    variantsTableHeaders() {
      return [
        { text: 'Description', value: 'description' },
        { text: 'SKU', value: 'sku' },
        { text: 'Stock Quantity', value: 'stockQuantity' },
      ];
    },
  },
  methods: {
    openModal(product) {
      this.product = product;
      this.$set(this.status, 'isModalOpen', true);
    },
    closeModal() {
      this.$set(this.status, 'isModalOpen', false);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
