<template>
  <div class="d-flex">
    <!-- LEFT CARD -->
    <v-card class="left-card">
      <!-- LIST VIEW -->
      <div class="d-flex align-center">
        <div style="color: #1398aa" class="fs-16 fw-600">Product List</div>
        <v-spacer />
        <v-btn class="ml-4" color="#1398AA" dark rounded>{{ products.length }} Items</v-btn>
        <v-btn class="ml-4" color="#1398AA" dark rounded @click="onSort">
          Alphabetical Order
          <v-icon v-if="this.status.isASC">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
        <v-text-field
          class="ml-4"
          background-color="#F3F3F3"
          solo
          dense
          rounded
          hide-details
          v-model="status.keyword"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#1398AA">mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-icon
          class="ml-4 btn-view-mode"
          :class="{ active: status.isListView }"
          @click="onSwitchView(true)"
          >mdi-format-list-bulleted</v-icon
        >
        <v-icon
          class="ml-4 btn-view-mode"
          :class="{ active: !status.isListView }"
          @click="onSwitchView(false)"
          >mdi-dots-grid</v-icon
        >
      </div>
      <perfect-scrollbar class="list-wrapper" v-if="status.isListView">
        <v-card
          class="mr-4 mb-4 pa-3 pb-8 text-center"
          width="180"
          v-for="product in filteredProducts"
          :key="product._id"
          @click="onSelectProduct(product)"
        >
          <div class="d-flex justify-space-between">
            <v-icon
              v-if="
                selectedProducts.length > 0 &&
                !!selectedProducts.find(_product => _product._id === product._id)
              "
              >mdi-check-box-outline</v-icon
            >
            <v-icon color="#CBD1D7" v-else>mdi-checkbox-blank-outline</v-icon>
            <v-icon color="#9C9C9C" @click.stop="$refs.variantModal.openModal(product)"
              >mdi-eye</v-icon
            >
          </div>
          <img
            class="mt-4"
            :src="product.images.length > 0 ? product.images[0] : defaultProductImageUrl"
            height="80"
          />
          <div style="color: #212b36" class="mt-4 mb-1 fs-14 fw-700 white-space-nowrap">
            {{ product.name | textEllipsis(16) }}
          </div>
          <div style="color: #959595" class="mb-1 fs-10">#{{ product.wcProductId }}</div>
          <div class="d-flex justify-center" v-if="product.categories.length > 0">
            <v-chip
              color="#1398AA"
              small
              dark
              v-for="category in product.categories"
              :key="category._id"
            >
              <span class="fs-8">{{ category.name }}</span>
            </v-chip>
          </div>
          <v-chip color="grey" small dark v-else> NO CATEGORY </v-chip>
          <div style="color: #7c7c7c" class="mt-2 fs-12 fw-700">
            {{ product.variants.length > 0 ? `${product.variants.length} variants` : 'No variant' }}
          </div>
          <div class="d-flex justify-center mt-4 px-8" v-if="false">
            <!-- <v-icon color="#9C9C9C" size="16">mdi-bookmark</v-icon> -->
            <!-- <v-icon color="#9C9C9C" size="16">mdi-lock</v-icon> -->
            <v-icon color="#9C9C9C" size="16" @click.stop="$refs.variantModal.openModal(product)"
              >mdi-eye</v-icon
            >
          </div>
        </v-card>
      </perfect-scrollbar>
      <!-- TABLE VIEW -->
      <div class="mt-6" v-else>
        <v-data-table
          :headers="productsTableHeaders"
          :items="filteredProducts"
          item-key="_id"
          hide-default-footer
          disable-pagination
          show-select
          v-model="selectedProducts"
        >
          <template v-slot:item.imageUrl="{ item }">
            <img
              :src="item.images.length > 0 ? item.images[0] : defaultProductImageUrl"
              height="32"
            />
          </template>
          <template v-slot:item.name="{ item }">
            <div style="color: #212b36" class="fs-12 fw-700">
              {{ item.name | textEllipsis(25) }}
            </div>
          </template>
          <template v-slot:item.productId="{ item }">
            <span style="color: #959595" class="fs-12 fw-700">#{{ item.wcProductId }}</span>
          </template>
          <template v-slot:item.variants="{ item }">
            <span style="color: #7c7c7c" class="fs-12 fw-700">{{
              item.variants.length ? `${item.variants.length} variants` : 'No variants'
            }}</span>
          </template>
          <template v-slot:item.units="{ item }">
            <span style="color: #959595" class="fs-12 fw-700"
              >{{
                item.variants.reduce((total, variant) => total + variant.stockQuantity, 0)
              }}
              units</span
            >
          </template>
          <template v-slot:item.totalSalesPrice="{ item }">
            <span style="color: #959595" class="fs-12 fw-700">${{ item.totalSalesPrice }}</span>
          </template>
          <template v-slot:item.type="{ item }">
            <div class="d-flex justify-center" v-if="item.categories.length > 0">
              <v-chip
                color="#1398AA"
                small
                dark
                v-for="category in item.categories"
                :key="category._id"
              >
                <span class="fs-8">{{ category.name }}</span>
              </v-chip>
            </div>
            <v-chip color="grey" small dark v-else> NO CATEGORY </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- <v-icon class="ml-4" color="#9C9C9C">mdi-bookmark</v-icon> -->
            <!-- <v-icon class="ml-4" color="#9C9C9C">mdi-lock</v-icon> -->
            <v-icon class="ml-4" color="#9C9C9C" @click.stop="$refs.variantModal.openModal(item)"
              >mdi-eye</v-icon
            >
          </template>
        </v-data-table>
      </div>
    </v-card>
    <!-- RIGHT CARD -->
    <v-card class="right-card" color="#70A6BD">
      <div class="mb-6 text-center fs-15 fw-700 white--text">
        List the selected products to have it data analyzed in the selected period
      </div>
      <v-btn
        dark
        rounded
        color="#465286"
        @click="$emit('compare', selectedProducts)"
        :disabled="selectedProducts.length === 0"
        >Compare Products</v-btn
      >
    </v-card>

    <variant-modal ref="variantModal" />
  </div>
</template>

<script>
import VariantModal from './VariantModal.vue';

export default {
  name: 'SelectView',
  components: {
    VariantModal,
  },
  props: ['products'],
  data: () => ({
    selectedProducts: [],
    status: {
      isListView: true,
      isASC: false,
      keyword: '',
    },
  }),
  computed: {
    productsTableHeaders() {
      return [
        { text: '', value: 'imageUrl' },
        { text: 'PRODUCT', value: 'name' },
        { text: 'PRODUCT ID', value: 'productId' },
        { text: 'VARIANTS', value: 'variants' },
        { text: 'UNITS', value: 'units' },
        { text: 'TOTAL SALES', value: 'totalSalesPrice' },
        { text: 'TYPE', value: 'type' },
        { text: '', value: 'actions' },
      ];
    },
    filteredProducts() {
      return this.products
        .filter(product => product.name.toLowerCase().includes(this.status.keyword.toLowerCase()))
        .sort((a, b) => {
          if (this.status.isASC && a.name > b.name) return 1;
          if (!this.status.isASC && a.name < b.name) return 1;
          return -1;
        });
    },
  },
  methods: {
    onSwitchView(isListView) {
      this.$set(this.status, 'isListView', isListView);
    },
    onSort() {
      this.$set(this.status, 'isASC', !this.status.isASC);
    },
    onSelectProduct(product) {
      if (this.selectedProducts.find(_product => _product._id === product._id)) {
        this.selectedProducts = this.selectedProducts.filter(
          _product => _product._id !== product._id,
        );
      } else this.selectedProducts.push(product);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-card {
  flex: 1;
  padding: 48px 32px 48px 48px;
  z-index: 1;

  .btn-view-mode {
    ::v-deep &::before {
      padding: 4px;
      border-radius: 50%;
      opacity: 1;
    }
    ::v-deep &::after {
      background: transparent;
    }

    &.active {
      ::v-deep &::before {
        background-color: #1398aa;
        color: #fff;
      }
    }
  }

  .list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    max-height: 450px;
    padding: 8px;
  }
}
.right-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -32px;
  padding: 0 32px 0 64px;
  width: 352px;
  min-height: 600px;
}
</style>
