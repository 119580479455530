var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-card',{staticClass:"left-card"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"fs-16 fw-600",staticStyle:{"color":"#1398aa"}},[_vm._v("Product List")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#1398AA","dark":"","rounded":""}},[_vm._v(_vm._s(_vm.products.length)+" Items")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#1398AA","dark":"","rounded":""},on:{"click":_vm.onSort}},[_vm._v(" Alphabetical Order "),(this.status.isASC)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-text-field',{staticClass:"ml-4",attrs:{"background-color":"#F3F3F3","solo":"","dense":"","rounded":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#1398AA"}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.status.keyword),callback:function ($$v) {_vm.$set(_vm.status, "keyword", $$v)},expression:"status.keyword"}}),_c('v-icon',{staticClass:"ml-4 btn-view-mode",class:{ active: _vm.status.isListView },on:{"click":function($event){return _vm.onSwitchView(true)}}},[_vm._v("mdi-format-list-bulleted")]),_c('v-icon',{staticClass:"ml-4 btn-view-mode",class:{ active: !_vm.status.isListView },on:{"click":function($event){return _vm.onSwitchView(false)}}},[_vm._v("mdi-dots-grid")])],1),(_vm.status.isListView)?_c('perfect-scrollbar',{staticClass:"list-wrapper"},_vm._l((_vm.filteredProducts),function(product){return _c('v-card',{key:product._id,staticClass:"mr-4 mb-4 pa-3 pb-8 text-center",attrs:{"width":"180"},on:{"click":function($event){return _vm.onSelectProduct(product)}}},[_c('div',{staticClass:"d-flex justify-space-between"},[(
              _vm.selectedProducts.length > 0 &&
              !!_vm.selectedProducts.find(function (_product) { return _product._id === product._id; })
            )?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',{attrs:{"color":"#CBD1D7"}},[_vm._v("mdi-checkbox-blank-outline")]),_c('v-icon',{attrs:{"color":"#9C9C9C"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.variantModal.openModal(product)}}},[_vm._v("mdi-eye")])],1),_c('img',{staticClass:"mt-4",attrs:{"src":product.images.length > 0 ? product.images[0] : _vm.defaultProductImageUrl,"height":"80"}}),_c('div',{staticClass:"mt-4 mb-1 fs-14 fw-700 white-space-nowrap",staticStyle:{"color":"#212b36"}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(product.name,16))+" ")]),_c('div',{staticClass:"mb-1 fs-10",staticStyle:{"color":"#959595"}},[_vm._v("#"+_vm._s(product.wcProductId))]),(product.categories.length > 0)?_c('div',{staticClass:"d-flex justify-center"},_vm._l((product.categories),function(category){return _c('v-chip',{key:category._id,attrs:{"color":"#1398AA","small":"","dark":""}},[_c('span',{staticClass:"fs-8"},[_vm._v(_vm._s(category.name))])])}),1):_c('v-chip',{attrs:{"color":"grey","small":"","dark":""}},[_vm._v(" NO CATEGORY ")]),_c('div',{staticClass:"mt-2 fs-12 fw-700",staticStyle:{"color":"#7c7c7c"}},[_vm._v(" "+_vm._s(product.variants.length > 0 ? ((product.variants.length) + " variants") : 'No variant')+" ")]),(false)?_c('div',{staticClass:"d-flex justify-center mt-4 px-8"},[_c('v-icon',{attrs:{"color":"#9C9C9C","size":"16"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.variantModal.openModal(product)}}},[_vm._v("mdi-eye")])],1):_vm._e()],1)}),1):_c('div',{staticClass:"mt-6"},[_c('v-data-table',{attrs:{"headers":_vm.productsTableHeaders,"items":_vm.filteredProducts,"item-key":"_id","hide-default-footer":"","disable-pagination":"","show-select":""},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
            var item = ref.item;
return [_c('img',{attrs:{"src":item.images.length > 0 ? item.images[0] : _vm.defaultProductImageUrl,"height":"32"}})]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"fs-12 fw-700",staticStyle:{"color":"#212b36"}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(item.name,25))+" ")])]}},{key:"item.productId",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"fs-12 fw-700",staticStyle:{"color":"#959595"}},[_vm._v("#"+_vm._s(item.wcProductId))])]}},{key:"item.variants",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"fs-12 fw-700",staticStyle:{"color":"#7c7c7c"}},[_vm._v(_vm._s(item.variants.length ? ((item.variants.length) + " variants") : 'No variants'))])]}},{key:"item.units",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"fs-12 fw-700",staticStyle:{"color":"#959595"}},[_vm._v(_vm._s(item.variants.reduce(function (total, variant) { return total + variant.stockQuantity; }, 0))+" units")])]}},{key:"item.totalSalesPrice",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"fs-12 fw-700",staticStyle:{"color":"#959595"}},[_vm._v("$"+_vm._s(item.totalSalesPrice))])]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [(item.categories.length > 0)?_c('div',{staticClass:"d-flex justify-center"},_vm._l((item.categories),function(category){return _c('v-chip',{key:category._id,attrs:{"color":"#1398AA","small":"","dark":""}},[_c('span',{staticClass:"fs-8"},[_vm._v(_vm._s(category.name))])])}),1):_c('v-chip',{attrs:{"color":"grey","small":"","dark":""}},[_vm._v(" NO CATEGORY ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"ml-4",attrs:{"color":"#9C9C9C"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.variantModal.openModal(item)}}},[_vm._v("mdi-eye")])]}}]),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1)],1),_c('v-card',{staticClass:"right-card",attrs:{"color":"#70A6BD"}},[_c('div',{staticClass:"mb-6 text-center fs-15 fw-700 white--text"},[_vm._v(" List the selected products to have it data analyzed in the selected period ")]),_c('v-btn',{attrs:{"dark":"","rounded":"","color":"#465286","disabled":_vm.selectedProducts.length === 0},on:{"click":function($event){return _vm.$emit('compare', _vm.selectedProducts)}}},[_vm._v("Compare Products")])],1),_c('variant-modal',{ref:"variantModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }