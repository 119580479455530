<template>
  <div class="wrapper">
    <!-- LEFT CARD -->
    <v-card class="left-card" color="#3E89A8" dark flat>
      <a class="d-flex fw-700" href="#" @click.prevent="$emit('back')">
        <v-icon size="20">mdi-chevron-left-circle-outline</v-icon>
        <span class="ml-2 fs-14 white--text">Back to Product Selection</span>
      </a>
      <div class="flex-1 d-flex justify-center align-center">
        <img style="opacity: 0.1" src="/img/logo-full.svg" height="100" />
      </div>
      <div class="mb-8">
        Click below to modify<br />
        the analyzed products.
      </div>
      <v-btn large rounded color="#465286">Product Analysis</v-btn>
    </v-card>
    <!-- RIGHT CARD -->
    <perfect-scrollbar class="list-container">
      <single-product-analytics
        :product="product"
        @viewDetail="onViewProductDetails"
        v-for="product in selectedProducts"
        :key="product._id"
      />
    </perfect-scrollbar>

    <detail-modal ref="detailModal" />
  </div>
</template>

<script>
import DetailModal from './DetailModal.vue';
import SingleProductAnalytics from './SingleProductAnalytics.vue';

export default {
  name: 'MultiAnalytics',
  components: {
    DetailModal,
    SingleProductAnalytics,
  },
  props: ['selectedProducts'],
  methods: {
    onViewProductDetails(product) {
      this.$refs.detailModal.openModal(product);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  padding: 4px 32px 4px 0;
  border-radius: 32px;
  background-color: #3e89a8;
}
.left-card {
  display: flex;
  flex-direction: column;
  padding: 50px 72px 50px 40px;
  min-width: 360px;
  text-align: center;
}

.list-container {
  flex: 1;
  padding: 0px 16px 0 4px;
  overflow-y: auto;
  max-height: 65vh;
}
</style>
